import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LogRocket from "logrocket";
import "./App.css";
import { useSelector } from "react-redux";

import Loader from "./Components/Loader";
import {
  CONTEXTUAL_RETRIEVAL_PROMPT,
  LLAMA_PARSE_PROMPT,
} from "./utils/defaultPrompts";
import DeployedBots from "./Pages/CreateNewBot/DeployedBots/DeployedBots";
import CustomerBot from "./Pages/CustomerBot/CustomerBot";

const Home = lazy(() => import("./Pages/Home/Home"));
const Organization = lazy(() =>
  import("./Pages/Auth/Organization/Organization")
);
const Login = lazy(() => import("./Pages/Auth/Login/Login"));
const Signup = lazy(() => import("./Pages/Auth/Signup/Signup"));
const AppDrawerNav = lazy(() => import("./Pages/CreateNewBot/AppDrawerNav"));
const ChatInterface = lazy(() => import("./Pages/HtmlEmbeds/ChatInterface"));

function App() {
  const [advancedMode, setAdvancedMode] = useState(true);
  const chatbot = useSelector((state) => state.chatbot);
  const [subscriptionPackage, setSubscriptionPackage] = useState(null);

  const [botData, setBotData] = useState({
    advancedMode: false,
    organization: "",
    creator: "",
    name: "Untitled application",
    problem_statement: "I want a helpful assistant.",
    "llm-finetuning": false,
    datasets: [],
    structured_datasets: [],
    finetuning_dataset: [],
    dynamicShot_dataset: [],
    rqa_prompt: `You are a helpful assistant.`,
    prompt_combinations: { "zero-shot": "You are a helpful assistant." },
    keys: {},
    parameter_key_names: {
      chunksize: {
        300: true,
        700: false,
        1800: false,
        4200: false,
        "sentence-window": false,
      },
      metric: {
        cosine: true,
        dotproduct: false,
        euclidean: false,
        hybrid: false,
      },
      retrieval_method: {
        basic: true,
        "multi-query": false,
        hyde: false,
        "complex-to-simple-query": false,
        context_retrieval: false,
      },
      rerank_method: {
        none: true,
        mmr: false,
        cohere: false,
        llm_rerank: false,
        pinecone: false,
        jina: false,
        nvidia: false,
      },
      top_k: { 1: true, 5: false, 10: false, 15: false, 20: false },

      embedding_model: { "text-embedding-ada-002": true, "fine-tuned": false },
      parser: {
        recursive_character_text_splitter: true,
        unstructuredio: false,
        llama_parse: false,
      },
      query_preprocess_method: {
        basic: true,
        "multi-query": false,
        hyde: false,
        "complex-to-simple-query": false,
        query_expansion: false,
      },
      metadata_filtering: { "add-metadata": false, "no-metadata": true },
      applications: {
        "no-application": true,
        slack: false,
        telegram: false,
        googledrive: false,
      },
      tools: {
        "no-tools": true,
        calendly: false,
        traditionalDb: false,
        "payment-system": false,
      },
      knowledgebase: {
        "no-knowledgebase": true,
        csv: false,
        websites: false,
        drive: false,
      },
    },
    query_preprocessing_prompts: {},
    golden_responses: [],
    golden_responses_option: "userQna",
    optimization_method: "grid-search",
    have_memory: true,
    response_length: {
      "detailed-answers": false,
      concise: false,
      "no-preference": true,
    },
    response_length_text: "",
    recency: false,
    recency_text: "",
    tone: { formal: false, informal: false, "no-preference": true },
    tone_text: "",
    latency: { critical: true, noCritical: false },
    references: false,
    references_text: "",
    sources: true,
    sources_text: "\nReturn sources of the documents used in context.",
    metadata_filtering_sub: {
      "all-document-types": false,
      "one-document-type": true,
    },
    metadata_filtering_categories: { "all-equal": true, "not-equal": false },
    metadata_categories: [],
    structured_file: null,
    finetuning_values: {
      llm: { "llama-3-8b": true, "gpt-3.5": false },
      epoch: { 1: true },
      learningRate: { 0.0001: "true" },
      batchSize: { 1: "true" },
      alphaRank: { 1: "true" },
      rank: { 1: "true" },
      optimization_method: "grid-search",
    },
    llm_params: {
      temperature: { 0: "true" },
      outputTokenLimit: { 0: "true" },
      frequencyPenalty: { 0: "true" },
      presencePenalty: { 0: "true" },
      llm: {
        "gpt-4o-mini": true,
        "gpt-4": false,
        "llama-7b-chat": false,
        "llm-finetuning": false,
      },
      prompts: {
        "zero-shot": true,
        "few-shot": false,
        "few-shot-random": false,
        "dynamic-few-shot": false,
        "chain-of-thought": false,
        "few-shot-cot-random": false,
      },
    },
    columnTypes: {},
    metadataDescription: {},
    workflow: {},
    headers: [],
    foundational: true,
    unstructuredColumnTypes: {},
    neo4j_credentials: {
      NEO4J_URI: "",
      NEO4J_USERNAME: "",
      NEO4J_PASSWORD: "",
      llm: "",
    },
    retrieval_prompts: {
      contextual_retrieval_prompt: CONTEXTUAL_RETRIEVAL_PROMPT,
    },
    parsing_instructions: {
      llama_parse_prompt: LLAMA_PARSE_PROMPT,
      llama_parse_mode: {
        accurate: false,
        premium: false,
        continuous: false,
        third_party_multimodal: false,
        auto: true,
      },
      exclude_title_page: false,
      bounding_box: "",
      title_page_number: "1",
      unstructuredio_mode: {
        fast: false,
        hi_res: false,
        auto: true,
        ocr_only: false,
      },
    },
    templates: true,
  });

  // data = {retrieval_0: {}, generation_1:{llm_params: {}, finetuning_values: {}}, toolname_index: <id>}

  useEffect(() => {
    const hasValidAppId = !!process.env.REACT_APP_LOG_ROCKET_PROJECT;
    const urlParams = window.location.href;
    console.log(
      "urlParams: ",
      urlParams.includes("app.queryloop-ai.com"),
      "\nAPI Validation: ",
      hasValidAppId
    );
    if (hasValidAppId && urlParams.includes("app.queryloop-ai.com")) {
      LogRocket.init(process.env.REACT_APP_LOG_ROCKET_PROJECT);
    }
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/add/organization" element={<Organization />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="/demo" element={<CustomerBot />} />
          <Route
            path="/dashboard/*"
            element={
              <AppDrawerNav
                botData={botData}
                setBotData={setBotData}
                advancedMode={advancedMode}
                setAdvancedMode={setAdvancedMode}
                subscriptionPackage={subscriptionPackage}
                setSubscriptionPackage={setSubscriptionPackage}
              />
            }
          />
          <Route path="/chat-interface" element={<ChatInterface />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
