import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orgService from "./orgService";
//get user from local storage
const organization = JSON.parse(localStorage.getItem("organization"));
// const token = JSON.parse(localStorage.getItem("token"));

const initialState = {
  organization: organization ? organization : null,
  users: [],
  usage: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  templatesData: [],
};

//register user

export const registerOrg = createAsyncThunk(
  "organization/registerOrganization",
  async (orgData, thunkAPI) => {
    try {
      return await orgService.registerOrg(orgData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//register user

export const addOwner = createAsyncThunk(
  "organization/addOwner",
  async (organization, thunkAPI) => {
    try {
      return await orgService.addOwner(organization);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update updateOrganizationDetails

export const updateOrganizationDetails = createAsyncThunk(
  "organization/updateAccountDetails",
  async (orgData, thunkAPI) => {
    try {
      const response = await orgService.updateOrganizationDetails({
        orgData,
      });
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get getOrganization
export const getOrganization = createAsyncThunk(
  "organization/getOrganizationByUser",
  async (id, thunkAPI) => {
    try {
      const response = await orgService.getOrganization(id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get getOrganization
export const get_organization_by_user = createAsyncThunk(
  "organization/get_organization_by_user",
  async (id, thunkAPI) => {
    try {
      const response = await orgService.get_organization_by_user(id);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get add new user

export const addNewUser = createAsyncThunk(
  "organization/addNewUser",
  async (userdata, thunkAPI) => {
    try {
      const response = await orgService.addNewUser(userdata);
      // If response includes an error status directly, reject it
      if (response?.status && response.status !== "User Successfully Created") {
        return thunkAPI.rejectWithValue(response);
      }
      return response; // Return successful response
    } catch (error) {
      const message =
        (error.response && error.response.data) || // Now passing structured error data
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message); // Reject with full message
    }
  }
);

//add users batch to organization

export const addMultipleUsers = createAsyncThunk(
  "organization/addMultipleUsers",
  async (userdata, thunkAPI) => {
    try {
      const response = await orgService.addMultipleUsers(userdata);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//getAllUsers

export const getAllUsers = createAsyncThunk(
  "organization/getAllUsers",
  async (data, thunkAPI) => {
    try {
      const response = await orgService.getAllUsers(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//updateUser

export const updateUser = createAsyncThunk(
  "organization/updateUser",
  async (data, thunkAPI) => {
    try {
      const response = await orgService.updateUser(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//deleteUser
export const deleteUser = createAsyncThunk(
  "organization/deleteUser",
  async (user, thunkAPI) => {
    try {
      const response = await orgService.deleteUser(user);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//validate org openai key
export const validateOpenAIKey = createAsyncThunk(
  "organization/validateOpenAIKey",
  async (orgData, thunkAPI) => {
    try {
      const response = await orgService.validateOpenAIKey(orgData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//validate org pinecone key
export const validatePineconeKey = createAsyncThunk(
  "organization/validatePineconeKey",
  async (orgData, thunkAPI) => {
    try {
      const response = await orgService.validatePineconeKey(orgData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//save org openai key
export const saveOpenAIKey = createAsyncThunk(
  "organization/saveOpenAIKey",
  async (orgData, thunkAPI) => {
    try {
      const response = await orgService.saveOpenAIKey(orgData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete org openai key
export const deleteOpenAIKey = createAsyncThunk(
  "organization/deleteOpenAIKey",
  async (orgData, thunkAPI) => {
    try {
      const response = await orgService.deleteOpenAIKey(orgData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete org pinecone key
export const deletePineconeKey = createAsyncThunk(
  "organization/deletePineconeKey",
  async (orgData, thunkAPI) => {
    try {
      const response = await orgService.deletePineconeKey(orgData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//save org pinecone key
export const savePineconeKey = createAsyncThunk(
  "organization/savePineconeKey",
  async (orgData, thunkAPI) => {
    try {
      const response = await orgService.savePineconeKey(orgData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//testUserKyes
export const testUserKyes = createAsyncThunk(
  "organization/testUserKyes",
  async (user, thunkAPI) => {
    try {
      const response = await orgService.testUserKyes(user);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get org usage
export const get_usage = createAsyncThunk(
  "organization/get_usage",
  async (org, thunkAPI) => {
    try {
      return await orgService.get_usage(org);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//generate api key
export const generate_APIKey = createAsyncThunk(
  "organization/generate_APIKey",
  async (org, thunkAPI) => {
    try {
      return await orgService.generate_APIKey(org);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete api key
export const deleteApiKey = createAsyncThunk(
  "organization/deleteApiKey",
  async (key, thunkAPI) => {
    try {
      return await orgService.deleteApiKey(key);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get org keys
export const getOrgKeys = createAsyncThunk(
  "organization/getOrgKeys",
  async (data, thunkAPI) => {
    try {
      return await orgService.getOrgKeys(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete api key
export const getTemplateData = createAsyncThunk(
  "organization/getTemplateData",
  async (data, thunkAPI) => {
    try {
      return await orgService.getTemplateData(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete organization
export const deleteOrganization = createAsyncThunk(
  "organization/deleteOrganization",
  async (org_id, thunkAPI) => {
    try {
      return await orgService.deleteOrganization(org_id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orgSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    adduser: (state, action) => {
      const newUsers = [...state.organization.users, action.payload];
      state.organization.users = newUsers;
    },
    resetOrganizationComplete: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      localStorage.clear();
    },
    deleteBotFromOrg: (state, action) => {
      console.log("DELETING: ", action.payload);
      const newOrg = state.organization.bot.filter(
        (bot) => bot["$oid"] !== action.payload
      );
      const newTemplates = state.organization.templates.filter(
        (bot) => bot !== action.payload
      );
      console.log("newTemplates: ", newTemplates);
      const newOptimizedCombinations = state.organization.optimizedCombinations;
      if (newOptimizedCombinations[action.payload]) {
        delete newOptimizedCombinations[action.payload];
      }
      console.log("newOptimizedCombinations: ", newOptimizedCombinations);
      state.organization = {
        ...state.organization,
        bot: newOrg,
        templates: newTemplates,
        optimizedCombinations: newOptimizedCombinations,
      };
    },
    resetOgranization: (state) => {
      state.organization = null;
    },
    modifyKeys: (state, action) => {
      state.organization = action.payload;
    },
    saveCancelStatus: (state, action) => {
      console.log(
        "ACTION: ",
        action.payload,
        "\nstate.organization: ",
        state.organization.subscription_cancellation_status
      );
      state.organization.subscription_cancellation_status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerOrg.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerOrg.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
        // state.token = action.payload.token;
      })
      .addCase(registerOrg.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.organization = null;
      })
      .addCase(addOwner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOwner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        // state.token = action.payload.token;
      })
      .addCase(addOwner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateOrganizationDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrganizationDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization.name = action.payload.name;
      })
      .addCase(updateOrganizationDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(getOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(get_organization_by_user.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_organization_by_user.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(get_organization_by_user.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addNewUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.status;

        // Add the new user to the users list
        state.users = [...state.users, action.payload.user];

        // Extract the new user's ID and update state.organization.users
        const newUserId = { $oid: action.payload.user._id }; // Assuming _id is the identifier
        state.organization.users = [...state.organization.users, newUserId];
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

        // Check for a specific error status from the backend
        state.message =
          action.payload.status || "An error occurred while adding the user";
      })
      .addCase(addMultipleUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMultipleUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Users added successfully";

        // Merge new users into the existing users list
        state.users = [...state.users, ...action.payload.users];

        // Extract user IDs and map them to the correct format, then update state.organization.users
        const newUserIds = action.payload.users.map((user) => ({
          $oid: user._id,
        })); // Map _id to the correct structure
        state.organization.users = [...state.organization.users, ...newUserIds];
      })
      .addCase(addMultipleUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.status;
        const updatedUser = action.payload.user;
        const email = action.payload.user.email;
        state.users = state.users.map((user) =>
          user.email === email ? { ...user, ...updatedUser } : user
        );
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.status;
        const userIdToRemove = action.meta.arg._id;
        console.log("userIdToRemove", userIdToRemove);
        state.organization.users = state.organization.users.filter(
          (user) => user.$oid !== userIdToRemove
        );
        state.users = state.users.filter(
          (user) => user._id["$oid"] !== userIdToRemove["$oid"]
        );
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.users = [];
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload;
      })
      .addCase(testUserKyes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(testUserKyes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.keys = null;
      })
      .addCase(testUserKyes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.keys = action.payload;
      })
      .addCase(validateOpenAIKey.pending, (state) => {
        //state.isLoading = true;
        state.isError = null;
      })
      .addCase(validateOpenAIKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organization.openai_key_validation =
          action.payload.message === "OpenAI key is valid";
        state.isSuccess = true;
      })
      .addCase(validateOpenAIKey.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
        state.organization.openai_key_validation = false;
      })
      .addCase(validatePineconeKey.pending, (state) => {
        //state.isLoading = true;
        state.isError = null;
      })
      .addCase(validatePineconeKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organization.pinecone_key_validation =
          action.payload.message === "OpenAI key is valid";
        state.isSuccess = true;
      })
      .addCase(validatePineconeKey.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
        state.organization.pinecone_key_validation = false;
      })
      .addCase(saveOpenAIKey.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(saveOpenAIKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organization.openai_key = action.payload.data;
        state.isSuccess = true;
        state.message = "OpenAI key saved.";
        state.organization.openai_key_validation = true;
      })
      .addCase(saveOpenAIKey.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
        state.organization.openai_key_validation = false;
      })
      .addCase(deleteOpenAIKey.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(deleteOpenAIKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organization.openai_key = "";
        state.isSuccess = true;
        state.organization.openai_key_validation = false;
      })
      .addCase(deleteOpenAIKey.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
        state.organization.openai_key_validation = false;
      })
      .addCase(deletePineconeKey.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(deletePineconeKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organization.pinecone_key = "";
        state.isSuccess = true;
        state.organization.pinecone_key_validation = false;
      })
      .addCase(deletePineconeKey.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
        state.organization.pinecone_key_validation = false;
      })
      .addCase(savePineconeKey.pending, (state) => {
        //state.isLoading = true;
        state.isError = null;
      })
      .addCase(savePineconeKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organization.pinecone_key = action.payload.data;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.organization.pinecone_key_validation = true;
      })
      .addCase(savePineconeKey.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })
      .addCase(get_usage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_usage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.usage = action.payload.usage;
      })
      .addCase(get_usage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getTemplateData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTemplateData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.templatesData = action.payload.templates;
      })
      .addCase(getTemplateData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(generate_APIKey.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generate_APIKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        const newApiKey = action.payload;

        // Find the index of an existing API key with the same name
        const existingKeyIndex = state.organization.api_keys.findIndex(
          (key) => key.name === newApiKey.name
        );

        if (existingKeyIndex !== -1) {
          // If found, override the existing key
          state.organization.api_keys[existingKeyIndex] = newApiKey;
        } else {
          // If not found, push the new key
          state.organization.api_keys.push(newApiKey);
        }
      })
      .addCase(generate_APIKey.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteApiKey.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteApiKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const deletedKeyName = action.payload.name;
        state.organization.api_keys = state.organization.api_keys.filter(
          (key) => key.name !== deletedKeyName
        );
      })
      .addCase(deleteApiKey.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOrgKeys.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrgKeys.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const keys = action.payload.keys;
        state.organization.openai_key.key = keys.openai_key;
        state.organization.pinecone_key.key = keys.pinecone_key;
      })
      .addCase(getOrgKeys.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});
export const {
  resetOgranization,
  resetOrganizationComplete,
  adduser,
  modifyKeys,
  deleteBotFromOrg,
  saveCancelStatus,
} = orgSlice.actions;
export default orgSlice.reducer;
