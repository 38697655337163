import axios from "axios";

const API = axios.create({
  baseURL: "/api/organization",
});

const UserAPI = axios.create({
  baseURL: "/api/user",
});

// developer api
const DevAPI = axios.create({
  baseURL: "/api/developer",
});

//register
const registerOrg = async (orgData) => {
  const data = { name: orgData };
  const response = await API.post("/create", data);
  localStorage.setItem("organization", JSON.stringify(response.data));
  return response.data;
};

// add owner
const addOwner = async (orgData) => {
  const { organization, user_id } = orgData;
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await API.post(
    `/addOwner/${organization}`,
    { owner: user_id },
    config
  );
  return response.data;
};

// get organization
const getOrganization = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await API.get(`/${id}`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching organization:", error);
    localStorage.setItem("user", null);
    return null; // Handle the error as needed
  }
};

// Get Organization by user
const get_organization_by_user = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await API.get(`/byuser/${id}`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching organization:", error);
    localStorage.setItem("user", null);
    return null; // Handle the error as needed
  }
};

// Add new user
const addNewUser = async (userdata, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const user = userdata;
  const org_id = userdata.organization;

  try {
    const response = await axios.post(
      `/api/user/adduser/${org_id}`,
      user,
      config
    );
    return response.data; // Successfully created user response
  } catch (error) {
    console.error("Error adding user:", error);
    // Return the complete error response data
    return error.response.data; // This includes `status` and other details
  }
};

// add multiple users
const addMultipleUsers = async (usersdata, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const data = usersdata.users;
  const org_id = usersdata.organization;

  try {
    const response = await axios.put(
      `/api/organization/addBatchUsers/${org_id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error adding user:", error);
    return error.message; // Handle the error as needed
  }
};

// Update user
const updateUser = async (data) => {
  try {
    const response = await axios.post(
      `/api/user/edit_by_email/${data.user_id}/${data.member_email}`,
      { userData: data.userData }
    );

    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    return error; // Handle the error as needed
  }
};

//get all users
const getAllUsers = async (data) => {
  try {
    const response = await UserAPI.post(`/get_multiple_users`, { users: data });
    return response.data.users;
  } catch (error) {
    console.error("Error getting users:", error);
    return error; // Handle the error as needed
  }
};

// delete user
const deleteUser = async (user, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `/api/user/delete_by_email/${user.email}`,
      { data: user.email },
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error adding user:", error);
    return error; // Handle the error as needed
  }
};

// update org details
const updateOrganizationDetails = async ({ orgData, token }) => {
  try {
    const response = await API.put("/users/updateOrganizationDetails", orgData);
    return response.data;
  } catch (error) {
    return error;
  }
};

// validate open ai key
const validateOpenAIKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const orgId = orgData.orgId;

  try {
    const response = await API.post(
      `/validate/openai/${orgId}`,
      orgData,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// validate pinecone key
const validatePineconeKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const orgId = orgData.orgId;

  try {
    const response = await API.post(
      `/validatePineconeKey/${orgId}`,
      orgData,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// validate anthropic key
const validateAnthropicKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const orgId = orgData.orgId;

  try {
    const response = await API.post(
      `/validate/claude/${orgId}`,
      orgData,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// validate anthropic key
const validateGeminiKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const orgId = orgData.orgId;

  try {
    const response = await API.post(
      `/validate/gemini/${orgId}`,
      orgData,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// set openai key
const saveOpenAIKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const orgId = orgData.orgId;
  const keyData = orgData.keyData;
  console.log("keyData", keyData);
  try {
    const response = await API.post(`/set/openai/${orgId}`, keyData, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete openai key
const deleteOpenAIKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const orgId = orgData.orgId;
  try {
    const response = await API.post(`/delete/openai/${orgId}`, null, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

// set pinecone key
const savePineconeKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const orgId = orgData.orgId;
  const keyData = orgData.keyData;
  try {
    const response = await API.post(
      `/setPineconeKey/${orgId}`,
      keyData,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete pinecone key
const deletePineconeKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const orgId = orgData.orgId;
  try {
    const response = await API.post(
      `/delete/PineconeKey/${orgId}`,
      null,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// set anthropic key
const saveAnthropicKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const orgId = orgData.orgId;
  const keyData = orgData.keyData;
  try {
    const response = await API.post(`/set/claude/${orgId}`, keyData, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete anthropic key
const deleteAnthropicKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const orgId = orgData.orgId;
  try {
    const response = await API.post(`/delete/claude/${orgId}`, null, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

// set gemini key
const saveGeminiKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const orgId = orgData.orgId;
  const keyData = orgData.keyData;
  try {
    const response = await API.post(`/set/gemini/${orgId}`, keyData, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete gemini key
const deleteGeminiKey = async (orgData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const orgId = orgData.orgId;
  try {
    const response = await API.post(`/delete/gemini/${orgId}`, null, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

// test user keys
const testUserKyes = async (keys) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await API.get(`/test-keys/`, keys, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching organization:", error);
    return null; // Handle the error as needed
  }
};

// get org usage
const get_usage = async (org) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const org_id = org.org_id;
  const usage_id = org.usage_id;

  const response = await API.get(`/usage/${org_id}/${usage_id}`, config);

  return response.data;
};

// create api key
const generate_APIKey = async (data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const org_id = data.orgId;
  const bot_id = data.bot_id;

  const response = await DevAPI.get(`/generate/${org_id}/${bot_id}`, config);

  return response.data;
};

// delete api key
const deleteApiKey = async (key) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      apiKey: key, // This sends the API key in the headers
    },
  };

  const response = await DevAPI.delete(`/delete`, config);
  return response.data;
};

// get org keys
const getOrgKeys = async (data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.get(`/keys/${data.org_id}`, config);
  return response.data;
};

//get all users
const getTemplateData = async (data) => {
  try {
    const response = await UserAPI.post(`/get_template_data`, data);
    return response.data.users;
  } catch (error) {
    console.error("Error getting users:", error);
    return error; // Handle the error as needed
  }
};

const deleteOrganization = async (org_id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await API.delete(`/deleteorganization/${org_id}`, config);
  return response.data;
};

const orgService = {
  registerOrg,
  updateOrganizationDetails,
  getOrganization,
  get_organization_by_user,
  addNewUser,
  updateUser,
  deleteUser,
  getAllUsers,
  addOwner,
  testUserKyes,
  addMultipleUsers,
  get_usage,
  validateOpenAIKey,
  saveOpenAIKey,
  savePineconeKey,
  generate_APIKey,
  deleteApiKey,
  deleteOpenAIKey,
  validatePineconeKey,
  deletePineconeKey,
  getTemplateData,
  getOrgKeys,
  deleteOrganization,
  deleteAnthropicKey,
  saveAnthropicKey,
  validateAnthropicKey,
  validateGeminiKey,
  saveGeminiKey,
  deleteGeminiKey,
};

export default orgService;
